import DeleteModal from "app/modals/DeleteModal";
import React, { useState } from "react";
import styled from "styled-components";
import { getcoinImage } from "utils/commonFunction";

import {  Singlecoin, edit, deleted  } from "utils/image";


const CoinsCard = ({ data, handleDeleteClick, handleEditClick }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => setOpenModal(!openModal);

  const deleteding = async () => {
    setLoading(true);
    await handleDeleteClick(data?._id);
    setLoading(false);
  };

  return (
    <>
      <DeleteModal
        confirmLoading={loading}
        handleOk={deleteding}
        openModal={openModal}
        handleCancel={handleModal}
      />
      <CoinCardStyled>
        <div className="upper">
          <div className="coin-label">
            <img src={Singlecoin} alt="Coin" />
            {data?.coins} Coins
          </div>

          <div className="actions">
            <img
              className="action-icon"
              src={edit}
              alt="Edit"
              onClick={() =>
                handleEditClick(data?.coins, data?.amount, data?._id)
              }
            />
            <img
              className="action-icon"
              src={deleted}
              alt="Delete"
              onClick={handleModal}
            />
          </div>
        </div>
        <hr></hr>
        <div className="doller-container">
          <img src={getcoinImage(data?.coins)} alt="" className="coin-icon" />
          <div className="amount">${data?.amount}</div>
        </div>
      </CoinCardStyled>
    </>
  );
};

export default CoinsCard;

const CoinCardStyled = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #e6f7ff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  border: 1px solid #892424;
  .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .coin-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  .action-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .icon {
    display: flex;
  }
  .doller-container {
    display: flex;
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;
  }
  .coin-icon {
    width: 70px;
    height: 50px;
  }
  hr {
    color: #e1e1e1;
    border: 1px solid;
    margin: 10px;
  }
  .amount {
    width: 100%;
    font-size: 21px;
    font-weight: 700;
    font-family: "Poppins";
    background-image: radial-gradient(#950000, #7b2424, #08213f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
    text-align: end;
  }
`;
