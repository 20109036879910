import React from "react";
import { ConfigProvider, Table } from "antd";
import styled from "styled-components";
import Loader from "../../loaders/Loader";

const TableComponent = ({
  loading,
  listing,
  columns,
  pagination,
  onChange,
  padding,
  scrollHeight,
}) => {
  return (
    <StyledTableContainer padding={padding}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: "#e4b67b",
              stickyScrollBarBorderRadius: 50,
            },
          },
          token: {
            fontSize: 14,
            lineType: "solid",
            margin: "5px 0",
            opacityLoading: "0.2",
            motionDurationSlow: "9s",
          },
        }}
      >
        <Table
          style={{ width: "100%" }}
          loading={loading && <Loader />}
          className="styled-table"
          dataSource={listing}
          columns={columns}
          scroll={scrollHeight ? { y: scrollHeight, x: "900px" } : false}
          pagination={pagination}
          onChange={onChange}
          sticky
        />
      </ConfigProvider>
    </StyledTableContainer>
  );
};

export default TableComponent;

const StyledTableContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  .ant-table {
    border-radius: 12px;
    overflow: hidden;
  }
  .ant-table-content {
    width: 100%;
    table {
      width: 100%;
      table-layout: auto !important;
    }
  }
  .ant-table-body {
    scrollbar-width: auto;
    scrollbar-color: auto;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 4px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background-color: #e1e6e6;
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #08213f;
  }
  .styled-table {
    width: 100%;
    .ant-table-thead > tr > th {
      background-color: #8b8686;
      color: #000000;
      font-weight: bold;
      text-align: start;
      padding: 10px;
    }
    .ant-table-tbody > tr > td {
      width: 100%;
      background-color: #e7f6fb;
      color: #012349;
      text-align: start;
      padding: ${({ padding }) => padding || "10px"};
      font-weight: 500;
      letter-spacing: 0.3px;
    }
    .ant-table-tbody > tr:hover > td {
      background-color: #c6ebfc;
    }

    .ant-table-tbody > tr > td:first-child {
      font-weight: bold;
    }
  }

  .table-image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }

  .serial-no {
    text-align: center;
  }

  .Image-section {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 10px;
  }
  .ant-table-wrapper .ant-table-cell-ellipsis {
    white-space: normal;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    th,
    td {
      padding: 8px 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    th {
      background-color: #f4f4f4;
      font-weight: bold;
    }
  }
`;
