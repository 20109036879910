import React, { useState } from "react";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import CircleProgress from "app/component/CircleProgress";
import SubscriptionType from "app/component/SubscriptionType";
import BlockUser from "app/modals/BlockUser";
import ImageTage from "app/component/ImageTage";
import { getAddress } from "utils/commonFunction";
import UserImageModal from "app/modals/UserImageModal";
import { age, ImageNotFound } from "utils/image";

const UserProfileCard = ({ blocked, details }) => {
  const [imageModalopen, setImageModalOpen] = useState(false);
  const [blockopen, setBlockopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const Info = details?.userDetails?.[0];
  const detail = details?.userStatistics?.[0];
  const [status, setStatus] = useState(details?.userDetails?.[0]?.idActive);

  const blockeding = async () => {
    setLoading(true);
    let res = await blocked();
    if (res) {
      setStatus(!status);
      handleCancelblock();
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setImageModalOpen(false);
  };

  const handleCancelblock = () => setBlockopen(false);

  return (
    <>
      <BlockUser
        openModal={blockopen}
        handleCancel={handleCancelblock}
        loading={loading}
        handleOk={blockeding}
        title={"User Status"}
        msg={status}
      />
      <UserImageModal
        imageArray={Info?.images}
        openModal={imageModalopen}
        handleCancel={handleCancel}
      />
      <UserDetailStyle>
        <div className="Userdetail">
          <div className="profile">
            <div>
              <div className="user-image-wrapper">
                <div className="profile">
                  <div className="progess-circles">
                    <CircleProgress
                      percentage={Info?.profilePercentage || 1}
                      size={160}
                    />
                  </div>
                  <div className="image-user-box">
                    <ImageTage
                      className="user-image"
                      src={Info?.images?.[0]?.imageUrl}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-box-name">
                <p className="profile-per">
                  {Info?.profilePercentage || 0}% Profile Complete
                </p>
                <h3 className="user-name-para">
                  {Info?.firstName} {Info?.lastName}
                </h3>
              </div>
            </div>

            <div
              className="more-image-box"
              onClick={() =>
                Info?.images?.[0]?.imageUrl && setImageModalOpen(true)
              }
            >
              <div className="more-images">
                <ImageTage
                  className="user-more-img"
                  src={Info?.images?.[0]?.imageUrl}
                  onError={ImageNotFound}
                />
                <p className="image-count">+{Info?.images?.length - 1}</p>
              </div>
              <p>Preview</p>
            </div>
          </div>

          <div className="sub-types">
            <p>Subscription:</p>
            <SubscriptionType name={true} type={Info?.subscriptionBadge} />
          </div>
          <hr className="hr-linei" />
          <div className="name-box">
            <div className="status-box">
              <div className="status-container">
                <p className="sub-type"> Profile Status :</p>
                {status ? (
                  <p style={{ color: "green", fontWeight: 600 }}>Active</p>
                ) : (
                  <p style={{ color: "red", fontWeight: 600 }}> Inactive</p>
                )}
              </div>
            </div>
            <hr className="hr-linei" />
            <div className="status-container"></div>
            {blocked && (
              <div
                className="status-btn"
                onClick={() => setBlockopen(true)}
                style={{ color: status ? "red" : "green" }}
              >
                {status ? "Deactivate" : "Activate"}
              </div>
            )}
          </div>

          <hr className="hr-linei" />
          <div className="counter">
            <div className="Likes">
              <p>{detail?.totalLikes || "0"}</p>
              <p className="para">
                {detail?.totalLikes > 1 ? "Likes" : "Like"}
              </p>
            </div>

            <div className="Likes">
              <p>{detail?.totalMatches || "0"}</p>
              <p className="para">
                {detail?.totalMatches > 1 ? "Matches" : "Match"}
              </p>
            </div>

            <div className="Likes">
              <p>{detail?.totalSuperLikes || "0"}</p>
              <p className="para">
                {detail?.totalSuperLikes > 1 ? "Superlikes" : "Superlike"}
              </p>
            </div>
          </div>

          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <FaUser size={18} />
              <p className="detail-label">Gender :</p>
              <p className="detail-value">{Info?.identity}</p>
            </div>
          </div>
          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <img className="ageicon" src={age} alt="" />
              <p className="detail-label">Age :</p>
              <p className="detail-value">{Info?.userAge}</p>
            </div>
          </div>

          {Info?.contact_number && (
            <>
              <hr className="hr-linei" />
              <div className="detail-row">
                <div className="icon">
                  <MdOutlinePhoneIphone size={18} />
                  <h3 className="detail-label">Phone :</h3>
                  <p className="detail-value">
                    {Info?.dial_code || ""} {Info?.contact_number || "N/A"}
                  </p>
                </div>
              </div>
            </>
          )}

          {Info?.email && (
            <>
              <hr className="hr-linei" />
              <div className="detail-row">
                <div className="icon">
                  <MdMarkEmailUnread size={18} />
                  <h3 className="detail-label">Email :</h3>
                  <p className="detail-value">{Info?.email || "N/A"}</p>
                </div>
              </div>
            </>
          )}

          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <IoLocation size={18} />
              <h3 className="detail-label">Location :</h3>
              <p className="detail-value">{getAddress(Info?.address)}</p>
            </div>
          </div>
        </div>
      </UserDetailStyle>
    </>
  );
};

export default UserProfileCard;

const UserDetailStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media (max-width: 480px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 380px;
  }
  @media (max-width: 380px) {
    width: 310px;
  }

  .hr-linei {
    background: gray;
  }
  .user-info {
    margin: 10px 0;
  }
  .heading-para {
    background-color: #007bff;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #0056b3;
  }

  .user-more-img {
    width: 100%;
    height: 100%;
    filter: blur(2px);
    border-radius: 15px;
    cursor: pointer;
  }
  .more-images {
    position: relative;
    width: 70px;
    height: 70px;
  }
  .image-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    font-weight: 600;
  }

  .flex-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0px;
  }
  .profile-per {
    text-align: start;
    font-weight: 600;
    margin: 5px 0;
    padding: 0;
  }
  .profile {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ant-progress-text {
      display: none;
    }
  }

  .Userdetail {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0 auto;
  }

  .user-image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .progess-circles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .image-user-box {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  .name-box {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .status-container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  .status-box {
    display: flex;
    gap: 3px;
  }
  .status-btn {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 20px;
    color: red;
    margin-left: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #dbdbdb;
    }
  }
  .eyes-images-show {
    color: #000000;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    opacity: 0.8;
    gap: 5px;
    &:hover {
      opacity: 1;
    }
  }

  .user-image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
  .profile-per {
    text-align: center;
    font-weight: 600;
    margin: 5px 0;
    padding: 0;
  }
  .user-detail {
    margin-top: 20px;
  }
  .sub-types {
    margin: 0;
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
    gap: 5px;
    font-weight: 600;
  }
  .sub-type {
    gap: 5px;
    font-weight: 600;
  }

  .detail-row {
    margin: 10px 0;
    font-size: 12px;
  }
  .ageicon {
    width: 16px;
    height: 16px;
  }

  .detail-label {
    width: 80px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .detail-value {
    font-size: 14px;
    font-weight: 500;
    color: #464545;
  }
  .more-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-weight: 600;
    cursor: pointer;
  }

  .icon {
    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: start;
    align-items: center;
  }

  .counter {
    display: flex;
    justify-content: space-between;
  }

  .name {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 18px;
  }

  .Likes {
    display: flex;
    flex-direction: column;
    padding: 4px 0 0 0;
    align-items: center;
  }

  .para {
    font-weight: 600;
  }

  .user-name-para {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;
