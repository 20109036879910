import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Heading, TotalCounter } from "utils/commonStyle";
import CoinsCard from "app/component/cards/CoinsCard";
import {
  coinsaddApi,
  coinsApi,
  coinsdeleteApi,
  coinsupdateApi,
} from "services/collection";
import { Form } from "antd";

import CoinsModal from "./CoinsModal";
import { errorMessage, successMessage } from "utils/commonFunction";
import Loader from "app/loaders/Loader";

const CoinsManagment = () => {
  const [open, setOpen] = useState(null);
  const [form] = Form.useForm();
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [productId, setProductId] = useState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const handleOk = () => {
    setOpen(false);
  };

  const showModal = () => {
    setIsEditing(false);
    form.resetFields();
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const cardListing = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("limit", pagination?.pageSize);
    params.append("page", pagination?.current);
    const req = await coinsApi(params.toString());
    if (req?.status === 200) {
      setCardList(req?.data?.data || []);
      setPagination((prev) => ({
        ...prev,
        total: req?.data?.totalUsers,
      }));
    } else {
      errorMessage(req);
    }
    setLoading();
  };
  const handleDeleteClick = async (e) => {
    const param = new URLSearchParams();
    param.append("productId", e);
    const res = await coinsdeleteApi(param);
    if (res?.status === 200) {
      successMessage(res);
      cardListing();
    } else {
      errorMessage(res);
    }
  };

  const handleEditClick = async (coins, amount, productId) => {
    setIsEditing(true);
    const dataToEdit = {
      amount: amount,
      coins: coins,
    };
    setProductId(productId);
    form.setFieldsValue(dataToEdit);
    setOpen(true);
  };
  const updateCoin = async (e) => {
    setBtnLoading(true);
    const payload = {
      ...e,
      productId: productId,
    };
    const req = await coinsupdateApi(payload);
    if (req?.status === 200) {
      successMessage(req);
      cardListing();
    } else {
      errorMessage(req);
    }
    handleCancel();
    form.resetFields();
    setIsEditing(false);
    setBtnLoading(false);
  };

  const handleAddSubmit = async (e) => {
    setBtnLoading(true);
    const req = await coinsaddApi(e);
    if (req?.status === 200) {
      successMessage(req);
      cardListing();
    } else {
      errorMessage(req);
    }
    handleCancel();
    setBtnLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    cardListing();
  }, []);

  useEffect(() => {
    if (!isEditing) {
      form.resetFields();
    }
  }, [isEditing, form]);

  return (
    <>
      <StyledCoinsManagment>
        <div className="header">
          <Heading>Coins</Heading>
          <div className="table-actions">
            <button
              className="add-card"
              onClick={() => {
                showModal();
                form.resetFields();
              }}
            >
              + Add Card
            </button>
            <TotalCounter> Total Cards:{pagination?.total} </TotalCounter>
          </div>
        </div>

        {loading ? (
          <div className="loading-container">
            <Loader />
          </div>
        ) : (
          <div className="cardlist">
            {cardList?.map((e) => (
              <CoinsCard
                key={e?.id}
                data={e}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
              />
            ))}
          </div>
        )}

        <div>
          <CoinsModal
            open={open}
            handleOk={handleOk}
            handleCancel={handleCancel}
            isEditing={isEditing}
            form={form}
            updateCoin={updateCoin}
            handleAddSubmit={handleAddSubmit}
            btnloading={btnloading}
          />
        </div>
      </StyledCoinsManagment>
    </>
  );
};

export default CoinsManagment;
const StyledCoinsManagment = styled.div`
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-select {
    width: 200px;
    max-width: 100%;
  }

  .dashboard-header {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .table-actions {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-shrink: 0;
  }

  .cardlist {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    column-gap: 10px;
    row-gap: 10px;
    /* height: calc(100vh - 100px); */
    /* overflow-y: scroll; */
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: normal;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #882222;
      border-radius: 10px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: auto;
    }
  }
  .add {
    display: flex;
    justify-content: end;
    margin: 20px 0px;
  }
  .add-card {
    color: #ffffff;
    background: linear-gradient(135deg, #000000, #08213f);
    font-size: 14px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-weight: 600;
  }

  .add-card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }

  .add-card:active {
    transform: translateY(-1px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }
  .loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
  }
`;
