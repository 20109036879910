import React from "react";
import styled from "styled-components";
import { plus, premium  } from "utils/image";

const SubscriptionType = ({ type, name }) => {
  const check = parseInt(type)

  if (check === 1)
    return (
      <SubStyle>
        <img src={premium} alt="" />
        {name && <p>Premium</p>}
      </SubStyle> 
    );
  else if (check === 2)
    return (
      <SubStyle>
        <img src={plus} alt="" />
        {name && <p>Plus</p>}
      </SubStyle>
    );
  else
    return (
      <SubStyle>
        <p>BASIC</p>
      </SubStyle>
    );
};

export default SubscriptionType;

const SubStyle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;
