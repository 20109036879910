import styled from "styled-components";

export const Heading = styled.p`
  width: 100%;
  text-align: start;
  font-weight: 600;
  font-size: 18px;
  color: #333;
  line-height: 20px;
`;
export const TableActionSpan = styled.div`
  width: 100%;
  cursor: pointer;
`;
export const TotalCounter = styled.div`
  width: fit-content;
  font-weight: bold;
  color: #333;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 880px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 5px;
  }
`;
export const HeaderFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  @media (max-width: 680px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;


