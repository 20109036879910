import axios from "axios";
import store from "../reduxApp/Store";

const time = 100000;

const BaseUrl = process.env.REACT_APP_DEV_URL;

//  const BaseUrl = "http://192.168.1.104:4090/"

const Api = axios.create({
  timeout: time,
  baseURL: BaseUrl,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

Api.interceptors.request.use(
  (config) => {
    if (store.getState().authSlice?.token !== null) {
      const token = `bearer ${store?.getState()?.authSlice?.token}`;
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default Api;
