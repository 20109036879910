import React, { memo } from "react";
import { ConfigProvider, Select } from "antd";
import styled from "styled-components";

function Selecter({
  allowClear,
  loading,
  placeholder,
  height,
  width,
  options,
  handleSelect,
}) {
  return (
    <>
      <SelecterStyle width={width} height={height}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                optionSelectedFontWeight: 500,
                fontSize: "14px",
                optionFontSize: 14,
                optionPadding: "4px 2px",
                colorPrimary: "transparent",
              },
            },
            token: {
              borderRadius: 20,
              colorPrimaryHover: "#131313",
            },
          }}
        >
          <Select
            style={{
              width: "100%",
              height: "100%",
            }}
            allowClear={allowClear || false}
            options={options}
            loading={loading}
            placeholder={placeholder}
            onChange={(e, label) => handleSelect(e, label)}
          />
        </ConfigProvider>
      </SelecterStyle>
    </>
  );
}

export default memo(Selecter);

const SelecterStyle = styled.div`
  width: ${(props) => props.width || "140px"};
  height: ${(props) => props.height || "40px"};

  @media (max-width: 780px) {
    width: 160px;
  }
  @media (max-width: 680px) {
    width: 100%;
  }
  .arrow-icon {
    color: #000000;
  }
`;
