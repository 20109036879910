import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Breadcrumbs = ({pathname}) => {
 const navigate = useNavigate()
 const onClickback = ()=>navigate(- 1)
  return (
    <Breadcrumbstyle onClick={onClickback}>
      <IoIosArrowBack />
      <p className="back-path-name">{pathname||"Go to back"}</p>
    </Breadcrumbstyle>
  );
};

export default Breadcrumbs;

const Breadcrumbstyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 0;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
cursor: pointer;

  .back-path-name{
  margin: 0;
  padding: 0;
  }
`;
