import { deleteApi, getApi, postApi, putApi } from "./methods";

const authloginUrl = "admin/loginAdmin";
export const loginAuthApi = (payload) => {
  return postApi(authloginUrl, payload);
};
const UserListingurl = "admin/getAllUsersListForAdmin?";
export const userListingApi = (payload) => {
  return getApi(UserListingurl + payload);
};

const DeleteUser = "admin/deleteUserAdmin";
export const deleteUserApi = (payload) => {
  return putApi(DeleteUser, payload);
};

const InfoUser = "admin/getUserDetailsAdmin?";
export const InfoUserUserApi = (payload) => {
  return getApi(InfoUser + payload);
};
const dashboardurl = "admin/getAdminDashboardDetails?";
export const dashboardApi = (payload) => {
  return getApi(dashboardurl + payload);
};
const blockedurl = "admin/blockingUserAdmin";
export const bockUserApi = (payload) => {
  return putApi(blockedurl, payload);
};
const blockedlistingurl = "admin/getBlockedUserList?";
export const bockUserlistingApi = (payload) => {
  return getApi(blockedlistingurl + payload);
};
const reportedurl = "admin/getReportUserList?";
export const reportedApi = (payload) => {
  return getApi(reportedurl + payload);
};

const forgoturl = "admin/forgetPassword?";
export const forgotedApi = (payload) => {
  return putApi(forgoturl, payload);
};
const subscriptionurl = "admin/getUserSubscriptionData?";
export const subscriptionApi = (payload) => {
  return getApi(subscriptionurl + payload);
};
const coinsurl = "/admin/getCoinShopData?";
export const coinsApi = (payload) => {
  return getApi(coinsurl + payload);
};
const coinsdeleteurl = "admin/deleteCoinsShopData?";
export const coinsdeleteApi = (payload) => {
  return deleteApi(coinsdeleteurl + payload);
};
const coinsupdateurl = "/admin/updateCoinsShopData?";
export const coinsupdateApi = (payload) => {
  return putApi(coinsupdateurl, payload);
};
const coinsaddurl = "/admin/addCoinsShopData?";
export const coinsaddApi = (payload) => {
  return postApi(coinsaddurl, payload);
};
const subscriptionUrl = "/admin/giveSubscriptionToUser";
export const postSubscriptionApi = (payload) => {
  return postApi(subscriptionUrl, payload);
};
