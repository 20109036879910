import { Button, Form, Input, Modal } from "antd";
import React from "react";
import styled from "styled-components";
import { Singlecoin } from "utils/image";


const CoinsModal = ({
  open,
  handleOk,
  handleCancel,
  isEditing,
  form,
  updateCoin,
  handleAddSubmit,
  btnloading,
}) => {
  return (
    <Modal open={open} onOk={handleOk} onCancel={handleCancel} footer={false}>
      <ModalWrapper>
        <div className="title">
        <img className="coin-image-add-modal" src={Singlecoin} alt="Coin" />{isEditing ? "Edit Your Card" : "Add Your Card"} <img className="coin-image-add-modal" src={Singlecoin} alt="Coin" />
        </div>
        <Form
          form={form}
          name="yourForm"
          className="yourForm"
          onFinish={isEditing ? updateCoin : handleAddSubmit}
          layout="vertical"
        >
          <Form.Item
            name="amount"
            label="Price"
            rules={[{ required: true, message: "Please input the price!" }]}
          >
            <Input className="input-field" type="number" placeholder="Price" />
          </Form.Item>
          <Form.Item
            name="coins"
            label="Coins"
            rules={[{ required: true, message: "Please input the coin!" }]}
          >
            <Input className="input-field" type="number" placeholder="Coin" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={btnloading}
              style={{
                margin: "20px",
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </ModalWrapper>
    </Modal>
  );
};

export default CoinsModal;

const ModalWrapper = styled.div`
  text-align: center;
  background: radial-gradient(#dbdbdb, transparent);
  padding: 20px 20px 0;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #820e32;
    text-align: center;
    letter-spacing: 1px;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  img.coin-image-add-modal {
    width: 30px;
}
  .add-card {
    color: #ffffff;
    background: #f5005a;
    font-size: 20px;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .input-field{
    width: 100%;
    height: 48px;
    border-radius: 13px;
    border: 1px solid #0a5e72;
    font-weight: 700;
    font-size: 16px;
    color: black;
}
label.ant-form-item-required {
    font-size: 18px;
    font-weight: 500;
}
    .yourForm{
     font-size:25px;
    }

    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

`;
