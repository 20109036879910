import React, { memo, useState } from "react";
import { ImSearch } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";

const Searchfield = ({ handleSearch }) => {
  let timeClearout = null;
  const [values, setValues] = useState("");

  const onSearch = (e) => {
    setValues(e?.target.value);
    clearTimeout(timeClearout);
    timeClearout = setTimeout(() => {
      handleSearch(e?.target.value);
    }, 400);
  };

  const allClearSearch = () => {
    if (values !== "") {
      handleSearch("");
      setValues("");
    }
  };

  return (
    <SearchStyle values={values}>
      <div className="search-icon-box">
        <input
          className="search-input"
          type="text"
          placeholder="Search"
          onChange={onSearch}
          value={values}
        />
        <ImSearch className="search-icon-field" color="#b9b9b9" />
        <IoClose className="close-icon-search" onClick={allClearSearch} />
      </div>
    </SearchStyle>
  );
};

export default memo(Searchfield);

const SearchStyle = styled.div`
  width: fit-content;
  @media (max-width: 890px) {
    width: 160px;
  }
  @media (max-width: 680px) {
    width: 100%;
  }
  .search-icon-box {
    position: relative;
    width: ${({ width }) => width || "300px"};
    height: ${({ height }) => height || "40px"};
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid #e4e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 890px) {
      width: 100%;
    }
    &:hover {
      border: 1px solid #131313;
    }
  }
  .search-input {
    width: 93%;
    height: 100%;
    border: none;
    background: transparent;
    outline: none;
    font-size: 14px;
    font-weight: 300;
    color: #0c0c0c;
    letter-spacing: 1px;
  }
  .search-icon-field {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    color: "#6b6b6b";
    cursor: pointer;
    transition: 0.5s;
  }
  .close-icon-search {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    color: "#6b6b6b";
    cursor: pointer;
    opacity: 0;
    transition: 0.5s;
  }

  .search-icon-box:hover .close-icon-search {
    opacity: ${({ values }) => (values !== "" ? 1 : 0)};
  }
  .search-icon-box:hover .search-icon-field {
    opacity: ${({ values }) => (values !== "" ? 0 : 1)};
  }
`;
