import { Modal } from "antd";
import styled from "styled-components";
import ImageSlider from "app/component/ImageSlider";


const UserImageModal = ({ imageArray, openModal, handleCancel }) => {

  return (
    <UserImageModalStyle>
      <Modal
        closeIcon={<h3 style={{color:"lightgray",}}>X</h3>}
        style={{ width: "fit-content" }}
        open={openModal}
        onCancel={handleCancel}
        footer={false}
        maskClosable={true}
      >
        <ImageSlider imageArray={imageArray} />
      </Modal>
    </UserImageModalStyle>
  );
};

export default UserImageModal;

const UserImageModalStyle = styled.div`
  width: fit-content;
`;
