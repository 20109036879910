import React, { memo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "app/component/breadCrumb/Breadcrumb";
import { logo, mainbackground } from "utils/image";

const PublicLayout = () => {
  const { pathname } = useLocation();

  return (
    <PublicLayoutStyle>
      {!(pathname === "/") && (
        <div className="back-btn-box">
          <Breadcrumbs />
        </div>
      )}

      <div className="img-log-container">
        <img src={logo} alt="logo app" style={{ height: "200px" }} />
      </div>
      <div className="auth-from-container">
        <Outlet />
      </div>
      <div className="signup-link gap-2">
        <p>© Copyrights 2024 Splash.All rights reserved.</p>
      </div>
    </PublicLayoutStyle>
  );
};
export default memo(PublicLayout);

const PublicLayoutStyle = styled.div`
  background-image: url(${mainbackground});
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  object-fit: cover;
  background-position: right;
  position: relative;
  @media (max-width: 880px) {
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 770px) {
    padding-bottom: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .auth-from-container {
    display: flex;
    justify-content: start;
    width: 50%;
    @media (max-width: 770px) {
      width: 100%;
      justify-content: center;
    }
  }
  .back-btn-box {
    position: absolute;
    top: 18px;
    left: 10px;
  }

  .img-log-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    animation: rotate2Sm 1.5s linear infinite;
  }

  .back-btn-box {
    top: 8px;
    left: 5px;
  }

  .signup-link {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-weight: 500;
    color: black;
    opacity: 0.8;
  }
`;
