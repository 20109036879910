import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
const Loader = ({size}) => {
  return (
    <Wrapper>
      <Spin   percent={false} size={size?size:"large"} style={{ color: "red" }} />
    </Wrapper>
  );
};
export default Loader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
