import { Link } from "react-router-dom";
import { memo, useState } from "react";
import { Field, Form, Formik } from "formik";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "utils/commonFunction";
import { validationSchema } from "utils/Validation";
import Loader from "app/loaders/Loader";
import { loginAuthApi } from "services/collection";
import { login } from "reduxApp/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // slkjfs
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const payload = values;
    const res = await loginAuthApi(payload);
    if (res?.status === 200) {
      dispatch(login(res.data));
      successMessage(res)
    } else {
      errorMessage(res);
    }
    setLoading(false);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LogingStyle>
      <div className="col-sm-12 col-md-6 form-items">
        <div className="container">
          <div className="row w-100">
            <div className="heading">
              <h2> Splash Dating Admin Panel.</h2>
            </div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form noValidate>
                  <div className="form-content">
                    <Field
                      name="email"
                      type="email"
                      className="input-field"
                      placeholder="Enter your email"
                    />
                    <div className="error">
                      {errors.email && touched.email ? errors.email : null}
                    </div>

                    <div className="password-field">
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="input-field"
                        placeholder="Password"
                      />
                      <button
                        type="button"
                        className="show-password-button"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    <div className="error">
                      {errors.password && touched.password
                        ? errors.password
                        : null}
                    </div>

                    <Link to={"/forgot"} className="forgot-password">
                      Forgot Password?
                    </Link>
                    <div className="btn-container">
                      {loading ? (
                        <Loader />
                      ) : (
                        <button className="btn-login" onSubmit={handleSubmit}>
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </LogingStyle>
  );
};

export default memo(Login);

const LogingStyle = styled.div`
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: start;
  align-items: center;
  @media (max-width: 770px) {
    width: 100%;
    justify-content: center;
  }

  .form-items {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 18px 38px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 51px #3ccfd1;
    @media (max-width: 880px) {
      width: 350px;
    }
    @media (max-width: 500px) {
      width: 98%;
      margin: auto;
      margin-bottom: 10px;
    }
  }
  .heading {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .heading h2 {
    font-weight: 700;
    background: linear-gradient(90deg, #4b6cb7, #182848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
    margin: 0;
  }

  .logo-icon {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #94d7db;

    .form-group {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background: #fff;
    }

    .form-group:focus {
      border-color: #ff5417;
      box-shadow: 0 0 5px rgba(255, 84, 23, 0.5);
    }

    .password-field {
      display: flex;
      align-items: center;
      position: relative;

      .show-password-button {
        position: absolute;
        right: 10px;
        background: transparent;
        border: none;
        cursor: pointer;
        color: #ff5417;
      }
    }
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-login {
    width: 100%;
    height: 40px;
    background: #ff5417;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #f34100;
    }
  }

  .signup-link {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-weight: 500;
    color: black;
    opacity: 0.8;
  }
  a.forgot-password {
    display: flex;
    justify-content: end;
    color: #005e89;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 12px;
    transition: 0.4s;
    &:hover {
      color: #08a4ec;
    }
  }

  .input-field {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border: 1px solid #ff5417;
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .container {
    width: 100%;
  }

  .error {
    height: 20px;
    color: red;
    font-weight: 600;
    font-size: 14px;
  }
`;
