import moment from "moment";
import { message } from "antd";
import { one1, one5, one10, one50, one100, one500, one1000 } from "./image";

export const getLogingType = (type) => {
  const checktype = parseInt(type);
  switch (checktype) {
    case 1:
      return "Email";
    case 2:
      return "Facebook";
    case 3:
      return "Instagram";
    case 4:
      return "Google";
    case 5:
      return "Apple";
    case 6:
      return "Phone Number";
    default:
      return "NAN";
  }
};

export const getcoinImage = (coin) => {
  const count = parseInt(coin);
  if (count >= 0 && count < 35) {
    return one1;
  } else if (count >= 35 && count < 50) {
    return one5;
  } else if (count >= 50 && count < 100) {
    return one10;
  } else if (count >= 100 && count < 250) {
    return one50;
  } else if (count >= 250 && count < 500) {
    return one100;
  } else if (count >= 500 && count < 1000) {
    return one500;
  } else if (count >= 1000) {
    return one1000;
  }
};

export const datePattern = (data, dayAdd) => {
  if (dayAdd) {
    if (data?.includes(":")) {
      return data
        ? moment(data).add(dayAdd, "days").format("MMM Do YYYY")
        : "N/A";
    } else {
      return data
        ? moment
            .unix(data / 1000)
            .add(dayAdd, "days")
            .format("MMM Do YYYY")
        : "N/A";
    }
  } else if (data.includes(":")) {
    return data ? moment(data).format("MMM Do YYYY") : "N/A";
  } else {
    return data ? moment.unix(data / 1000).format("MMM Do YYYY") : "N/A";
  }
};

export const getAddress = (address) => {
  const country = address?.country?.name || "";
  const state = address?.state?.name || "";
  const city = address?.city?.name || "";
  let parts = [];
  if (country === "United States") {
    if (state) parts.push(state);
    if (city) parts.push(city);
  } else {
    if (country) parts.push(country);
    if (state) parts.push(state);
    if (city) parts.push(city);
  }
  if (parts?.length === 0) return "N/A";
  else return parts.join(", ");
};

export const snoIndex = ({ index, pagination }) => {
  return (
    <p className="serial-no">
      {pagination?.current * pagination?.pageSize -
        (pagination?.pageSize - (index + 1))}
    </p>
  );
};

export const errorMessage = (res) =>
  message.error(res?.message || res || "Something went wrong");
export const successMessage = (res) =>
  message.success(res?.message || res || "Something went wrong");

export  function calculateHeight(subHeight) {
  const viewportHeight = window.innerHeight;
  const height = viewportHeight - subHeight;
  return `${height}px`;
}
