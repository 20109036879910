import React, { useState } from "react";
import styled from "styled-components";
import Loader from "app/loaders/Loader";
import { errorMessage, successMessage } from "utils/commonFunction";
import { forgotedApi } from "services/collection";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const forgotEmailsend = async () => {
    if (email !== null && email !== "") {
      setLoading(true);
      setError(null);
      const payload = {
        contactEmail: email,
      };
      const res = await forgotedApi(payload);
      if (res?.status === 200) {
        successMessage("Email sent successfully");
      } else {
        errorMessage(res);
      }
      setLoading(false);
    } else {
      setError("Email is required");
    }
  };

  return (
    <>
     
    <FormContainer>
      <h2>Forgot Password</h2>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Enter your email"
        onChange={(e) => setEmail(e?.target?.value)}
      />
      <p className="error-para">{error}</p>
      <div className="btn-container">
        {loading ? (
          <Loader />
        ) : (
          <button className="btn-login" onClick={forgotEmailsend}>Reset Password</button>
        )}
      </div>
    </FormContainer>
    
    </>
 
  );
};

export default ForgotPassword;


const FormContainer = styled.div`
  background-color: #89d6d7;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    text-align: left;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 14px;
    box-sizing: border-box;

    &:focus {
      border: 2px solid #ff5417;
      outline: none;
    }
  }
  .btn-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }
  .btn-login {
    width: 100%;
    height: 40px;
    background: #ff5417;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #ff4400;
    }
  }
    .error-para{
     margin: 0;
    padding: 0;
    text-align: start;
    color: red;
    font-size: 14px;
    font-weight: 500;
    height: 27px;
    margin-left: 8px;
}
    
.btn-container{
display:flex;
justify-content:center; 
}
`;
