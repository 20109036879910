import { Modal } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { InfoUserUserApi } from "services/collection";
import UserProfileCard from "app/page/users/UserProfileCard";
import Loader from "app/loaders/Loader";
import { errorMessage } from "utils/commonFunction";

const UserDetailModal = ({ blocked, openModal, handleCancel, userId }) => {
  const id = userId;
  const [detail, setDetail] = useState();
  const [loading, setloading] = useState();

  const InfoUser = async () => {
    if (id) {
      setloading(true);
      const params = new URLSearchParams();
      params.append("userId", id);
      const res = await InfoUserUserApi(params);
      if (res?.status === 200) {
        setDetail(res?.data);
      } else {
        errorMessage(res)
      }
      setloading(false);
    }
  };

  useEffect(() => {
    InfoUser();
  }, [openModal]);

  return (
    <Modal
      width={"fit-content"}
      open={openModal}
      onCancel={handleCancel}
      footer={false}
    >
      <ConfirmModalStyle>
        {loading ? (
          <div className="warppermodal">
            <Loader />
          </div>
        ) : (
          <UserProfileCard blocked={blocked} details={detail} />
        )}
      </ConfirmModalStyle>
    </Modal>
  );
};

export default UserDetailModal;

const ConfirmModalStyle = styled.div`
  width: 450px;
  @media (max-width: 480px) {
    width: 100%;
  }

  .warppermodal {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    height: 50vh;
  }
`;
