import Api from "./Interceptor";


// ---get----//
export const getApi = async (url, data) => {

    try {
      let result = await Api.get(url, data);
      if (result?.status === 200) {
        return result?.data;
      }
    } catch (e) {
      return e?.response?.data;
    }
  };

// ---post ----//
  export const postApi = async (url,data)=>{
    try{
        let result = await Api.post(url,data);
        if(result?.status === 200){
          return result?.data;
        }

    } catch (e){
        return e?.response?.data
    }
  }
  export const patchApi = async (url,data)=>{
    try{
        let result = await Api.patch(url,data);
        if(result?.status === 200){
          return result?.data;
        }

    } catch (e){
        return e?.response?.data
    }
  }

  // ---put ----//
  export const putApi = async(url,data)=>{
    try{
        let result = await Api.put(url,data);
        if(result?.status === 200){
            return result?.data
        }

    } catch (e){
        return e?.response?.data
    }
  }

  // ---delete ----//
  export const deleteApi = async(url,data)=>{
    try{
        let result = await Api.delete(url,data);
        if(result?.status === 200){
            return result?.data
        }

    } catch (e){
        return e?.response?.data
    }
  }