import React from "react";
import styled from "styled-components";
import PaginationCard from "./PaginationCard";
import Loader from "app/loaders/Loader";

const Cardwrapper = ({
  table,
  tableCard,
  loading,
  pagination,
  setPagination,
}) => {

  return (
    <CardWarpper>
      <div className="table-component">{table}</div>
      {pagination && (
        <div className="pagnation-components">
          <PaginationCard
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
      {loading ? (
        <div className="loading-container">
          <Loader />
        </div>
      ) : tableCard?.length !== 0 ? (
        <div className="card-container">{tableCard}</div>
      ) : (
        <div className="loading-container">Data Not Found</div>
      )}
    </CardWarpper>
  );
};

export default Cardwrapper;

export const CardWarpper = styled.div`
  width: 100%;
  .table-component {
    width: 100%;
  }
  .pagnation-components {
    display: none;
    width: 100%;
    margin: 5px;
    justify-content: end;
  }
  .loading-container {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 25px;
    color: #08213f;
  }
  .card-container {
    width: 100%;
    background: #c6ebfc;
    border-radius: 20px;
    height: calc(100vh - 200px);
    display: none;
    padding: 10px;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 15px;
    row-gap: 15px;
    @media (max-width: 1400px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 650px) {
      grid-template-columns: auto;
    }
    overflow-y: scroll;
    scrollbar-width: auto;
    scrollbar-color: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #08213f;
    }
  }
  @media (max-width: 1000px) {
    .table-component {
      display: none;
    }
    .card-container {
      display: grid;
    }

    .pagnation-components {
      display: flex;
    }
    .loading-container {
      display: flex;
    }
  }
`;
