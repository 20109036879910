
import { Modal } from "antd";
import styled from "styled-components";
import ReportedCard from "./ReportedCard";


const ReportUserDetailModal = ({
  blocked,
  openModal,
  handleCancel,
  detail,
}) => {
  return (
    <Modal
      width={"fit-content"}
      open={openModal}
      onCancel={handleCancel}
      footer={false}
    >
      <ConfirmModalStyle>
        <ReportedCard blocked={blocked} details={detail} />
      </ConfirmModalStyle>
    </Modal>
  );
};

export default ReportUserDetailModal;

const ConfirmModalStyle = styled.div`
  max-width: 450px;
`;
