import { memo } from "react";
import { ConfigProvider, Pagination } from "antd";



const PaginationCard = ({pagination,setPagination }) => {
  

  const onChange = (page) => {
    setPagination({...pagination, current:page})
  };


  return (
      <ConfigProvider
        // theme={{
        //   token: {
        //     borderRadius: 100,
        //     colorBgTextActive:"green",
        //     colorBgTextHover:"#13131350",
        //     colorPrimary:"white"
            
        //   },
        //   components: {
        //     Pagination: {
        //        itemActiveBg:"#131313"
        //     },
        //   },
        // }}
      >
        <Pagination
          showLessItems={false}
          showSizeChanger={false}
          current={pagination?.current}
          onChange={onChange}
          total={pagination?.total}
          defaultCurrent={1}
          defaultPageSize={pagination?.pageSize}
          showQuickJumper={false}
        />
      </ConfigProvider>
 
  );
};
export default memo(PaginationCard);



