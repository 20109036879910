import React from "react";
import styled from "styled-components";
import { deleteIon, info2, block } from "../../../utils/image";
import { CgUnblock } from "react-icons/cg";
import { MdSubscriptions } from "react-icons/md";

const TableAction = ({ tableAction, record, handlClick }) => {
  const action = (type) => {
    handlClick({ ...record, actiontype: type });
  };
  if (tableAction === undefined) {
    tableAction = {
      info: true,
      delete: true,
      block: true,
      unblock: true,
      subscriptions: true,
    };
  }

  return (
    <ActionStyle
      actiondisable={
        tableAction?.actionDisabled ? record?.actionDisabled : true
      }
    >
      {tableAction.info && (
        <img
          className="table-action Info"
          onClick={(e) => action("info")}
          src={info2}
          alt=""
        />
      )}
      {tableAction.delete && (
        <img
          className="table-action delete"
          onClick={(e) => action("delete")}
          src={deleteIon}
          alt=""
        />
      )}
      {tableAction.block && (
        <img
          className="table-action block"
          onClick={(e) => action("block")}
          src={block}
          alt=""
        />
      )}
      {tableAction.unblock && (
        <CgUnblock
          className="table-actionun unblock"
          onClick={(e) => action("unblock")}
        />
      )}
      {tableAction.subscriptions && (
        <MdSubscriptions
          className="table-actionun subscriptions"
          onClick={(e) => action("subscriptions")}
        />
      )}
    </ActionStyle>
  );
};

export default TableAction;

const ActionStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  opacity: ${({ actiondisable }) => (actiondisable ? 1 : 0.5)};
  pointer-events: ${({ actiondisable }) =>
    actiondisable ? "visibleFill" : "none"};
  .table-action {
    height: 25px;
    width: 25px;
    border-radius: 5px;
    padding: 3px;
    background: #e2d9d9;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .table-actionun {
    height: 27px;
    width: 28px;
    border-radius: 5px;
    padding: 2px;
    background: #e2d9d9;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .Info:hover {
    background: #83e783;
    color: white;
    filter: invert(1);
  }

  .delete:hover {
    background: #ff0101;
    color: white;
    filter: invert(1);
  }

  .block:hover {
    background: #f9fd02;
    color: white;
    filter: invert(1);
  }
  .unblock:hover {
    background: #00ff2a;
    color: #2600ff;
  }
  .subscriptions:hover {
    background: #000000;
    color: #00ff62;
  }

  @media (max-width: 768px) {
    gap: 3px;
    .table-action {
      height: 18px;
      width: 18px;
    }
  }

  @media (max-width: 580px) {
    .table-action {
      height: 25px;
      width: 25px;
    }
  }
`;
