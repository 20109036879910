import TableAction from "app/component/tables/TableAction";
import React from "react";
import styled from "styled-components";

const DashoardTableCard = ({ data, handleAction }) => {
  return (
    <TCardUserStyle>
      <p className="name-user">{data?.fullName}</p>
      <div className="name-status-box">
        <div className="status-box">
          <p className="heading-lable">Status</p>
          <p className="status-check">{data?.checkStatus}</p>
        </div>
      </div>

      <div className="action-box">
        <TableAction
          record={data}
          tableAction={{ info: true }}
          handlClick={handleAction}
        />
      </div>
      <div className="details-container">
        <div className="details-lable-name">
          <p className="heading-lable">Joined :</p>
          <p className="heading-value">{data?.joinedDate}</p>
        </div>
        <div className="details-lable-name">
          <p className="heading-lable">Gender :</p>
          <p className="heading-value">{data?.identity}</p>
        </div>
        <div className="details-lable-name">
          <p className="heading-lable">Age :</p>
          <p className="heading-value">{data?.userAge}</p>
        </div>
        <div className="details-lable-name">
          <p className="heading-lable">Login Type :</p>
          <p className="heading-value">{data?.loginTypecheck}</p>
        </div>
        <div className="details-lable-nam">
          <p className="heading-lable">Location :</p>
          <p className="heading-value">{data?.location}</p>
        </div>
      </div>
    </TCardUserStyle>
  );
};

export default DashoardTableCard;

const TCardUserStyle = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 3px #939393;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  border: 1px solid #e4b67b;
  &:hover {
    transform: scale(1.03);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }

  .action-box {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .name-user {
    font-size: 18px;
    font-weight: 600;
    color: #FF5722;
}
  .status-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px 0;
  }
  .userimge-tag {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    object-fit: cover;
  }
  .details-container {
    display: flex;
    width: 100%;
    gap: 8px;
    flex-direction: column;
  }
  .details-lable-name {
    display: flex;
    gap: 20px;
  }
  .name-status-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px 0;
  }
  .status-check {
    font-size: 12px;
    color: green;
    font-weight: 600;
  }
  .heading-lable {
    width: 100px;
    font-size: 12px;
    font-weight: 600;
  }
  .heading-value {
    font-size: 12px;
    font-weight: 500;
    color: #6d6a6a;
  }
`;
