export const bydefaultLimit = 10;
export const bydefaultCurrentPage = 1;

export const sortOptions = [
  { label: "Oldest", value: 1 },
  { label: "Latest", value: -1 },
];
export const statusOption = [
  { label: "Active Users", value: 1 },
  { label: "Inactive Users", value: 2 },
  { label: "All Users", value: false },
];


export const subscriptionOption = [
  { label: "Premium", value: 1 },
  { label: "Plus", value: 2 },
  { label: "Basic", value: 3 },
];
export const subscriptionStatusOptions = [
  { label: "Active", value: "1" },
  { label: "Expired", value: "0" },
  { label: "Cancelled", value: "2" },
];