import { userprofileicon } from "utils/image";
import React, { memo } from "react";
const Imageurl = process.env.REACT_APP_IMAGE_URL;

const ImageTage = ({ className, src, onError, onClick }) => {
  let linkurl = Imageurl;
  if (src) linkurl = linkurl + src;
  return (
    <img
      onClick={onClick}
      src={linkurl}
      className={className}
      alt=""
      onError={(e) => (e.target.src = onError || userprofileicon)}
    />
  );
};

export default memo(ImageTage);
