import { Modal } from "antd";
import styled from "styled-components";
import Loader from "app/loaders/Loader";


const BlockUser = ({
  title,
  loading,
  openModal,
  handleCancel,
  handleOk,
  msg,
}) => {
  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      footer={false}
      width={"fit-content"}
      closable={false}
    >
      <ConfrimModalStyle>
        <h2>{title}</h2>
        <p
          className="modal-text-parra"
          style={msg ? { color: "red" } : { color: "green" }}
        >
          {msg
            ? "Are you sure to want deactivate  this User?"
            : `Are you sure to want to activate user?`}
        </p>
        <div className="btn-contianer-modal">
          {loading ? (
            <Loader />
          ) : (
            <>
              <button
                className="btn-modal"
                style={{ color: "red" }}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                onClick={handleOk}
                className="btn-modal"
                style={{ color: "green" }}
              >
                Ok
              </button>
            </>
          )}
        </div>
      </ConfrimModalStyle>
    </Modal>
  );
};

export default BlockUser;

const ConfrimModalStyle = styled.div`
  background: radial-gradient(#8fdcff, transparent);
  padding: 20px;
  border-radius: 10px;
  .btn-modal {
    padding: 5px 12px;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    color: red;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      box-shadow: 1px 1px 1px 1px;
    }
  }
  .modal-text-parra {
    color: black;
    font-weight: 500;
    font-size: 16px;
  }
  .btn-contianer-modal {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
  }
`;
