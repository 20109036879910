import React from "react";
import styled from "styled-components";
import { CgMenu } from "react-icons/cg";
import { TbMenuOrder } from "react-icons/tb";
import { logo } from "../../../utils/image";
import { useSelector } from "react-redux";

const Header = ({ handleClick, navopenClose }) => {
  const userDetails = useSelector((store) => store?.authSlice?.user);

  return (
    <HeaderContainer>
      <div className="left-section" onClick={handleClick}>
        {navopenClose ? (
          <CgMenu className="side-open-icon" size={25} />
        ) : (
          <TbMenuOrder className="side-open-icon" size={25} />
        )}
        <p className="title">SPLASH MATCH</p>
      </div>
      <div className="right-section">
        <div className="splash-admin">
          <div className="admin-text">
            {userDetails?.firstName} {userDetails?.lastName}
          </div>
          <div className="admin-icon">{userDetails?.firstName?.[0]}</div>
          <img src={logo} alt="Logo" className="logoheader" />
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4ebedd;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px;

  @media (max-width: 1080px) {
    position: fixed;
    top: 0;
    z-index: 1;
  }
  .left-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    @media (max-width: 760px) {
      font-size: 16px;
    }
  }

  .side-open-icon {
    cursor: pointer;
  }
  .right-section {
    display: flex;
    align-items: center;

    .splash-admin {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .admin-text {
        font-size: 14px;
        color: #ffffff;
        margin-right: 8px;
      }

      .admin-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        @media (max-width: 460px) {
          display: none;
        }
      }
    }
  }

  .profile-section {
    display: flex;
    align-items: center;

    .profile-icon {
      color: #ffffff;
      font-size: 20px;
      margin-right: 8px;
    }

    .profile-text {
      font-size: 14px;
      color: #ffffff;
    }
  }

  .logoheader {
    margin-top: -5px;
    width: 40px;
    height: 40px;
  }
`;
