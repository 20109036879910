import SubscriptionType from "app/component/SubscriptionType";
import { datePattern, getAddress, getLogingType } from "./commonFunction";

// --Dasboard -----------------------------//
export const dashboardModify = async (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newdata = data?.map((el, index) => {
    console.log(el,'sfwefwef')
    return {
      index: index + 1,
      ...el,
      fullName: `${el?.firstName} ${el?.lastName}`,
      checkStatus: el?.idActive ? (
        <p style={{ color: "green", fontWeight: 600 }}>Active</p>
      ) : (
        <p style={{ color: "red", fontWeight: 600 }}>Inactive</p>
      ),
      joinedDate: datePattern(el?.createdAt),
      location: getAddress(el?.address),
      loginTypecheck: getLogingType(el?.signupType),
    };
  });
  return newdata;
};

// --User -----------------------------//

export const userModify = async (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newdata = data?.map((el, index) => {
    return {
      ...el,
      index: index,
      profileName: {
        thumbnail: `${el?.images?.[0]?.imageUrl}`,
        fullName: `${el?.firstName} ${el?.lastName}`,
      },
      checkStatus: el?.idActive ? (
        <p style={{ color: "green", fontWeight: 600 }}>Active</p>
      ) : (
        <p style={{ color: "red", fontWeight: 600 }}>Inactive</p>
      ),
      createdAt: datePattern(el?.createdAt),
      location: getAddress(el?.address),
      loginTypecheck: getLogingType(el?.signupType),
    };
  });

  return newdata;
};

// --Reported -----------------------------//

export const reportModify = async (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newdata = data?.map((el, index) => {
    return {
      index: index,
      ...el,
      profileName: {
        thumbnail: `${el?.recieverdata?.images?.[0]?.imageUrl}`,
        fullName: `${el?.recieverdata?.firstName} ${el?.recieverdata?.lastName}`,
      },
      thumbnail: `${el?.recieverdata?.[0]?.images?.[0]?.imageUrl}`,
      repotedBy: `${el?.senderData?.[0]?.firstName} ${el?.senderData?.[0]?.lastName}`,
      repotedreason: `${el?.reportTitle}`,
      checkStatus: el?.recieverdata?.idActive ? (
        <p style={{ color: "green", fontWeight: 600 }}>Active</p>
      ) : (
        <p style={{ color: "red", fontWeight: 600 }}>Inactive</p>
      ),
      reportedDate: datePattern(el?.createdAt),
      location: getAddress(el?.address),
      loginTypecheck: getLogingType(el?.signupType),
    };
  });
  return newdata;
};

// -- subscription -----------------------------//
export const subcriptionModify = async (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const newdata = data?.map((el, index) => {
    let renewedDate = datePattern(el?.subscriptionData?.[0]?.expiryDate, 1);
    if (el?.subscriptionData?.[0]?.currentInAppPurchaseStatus === "expired") {
      renewedDate = "Expired ";
    } else if (
      el?.subscriptionData?.[0]?.currentInAppPurchaseStatus === "cancel"
    ) {
      renewedDate = "Cancelled";
    }
    return {
      ...el,
      index: index,
      profileName: {
        thumbnail: `${el?.images?.[0]?.imageUrl}`,
        fullName: `${el?.firstName} ${el?.lastName}`,
      },
      subscriptionBadgetype: (
        <SubscriptionType name={true} type={el?.subscriptionBadge} />
      ),

      startDateset: el?.subscriptionData?.[0]?.purchaseDate
        ? datePattern(el?.subscriptionData?.[0]?.purchaseDate)
        : "N/A",
      endDateset: el?.subscriptionData?.[0]?.expiryDate
        ? datePattern(el?.subscriptionData?.[0]?.expiryDate)
        : "N/A",
      renewedDateset: renewedDate,
      actionDisabled:
        el?.subscriptionData?.[0]?.currentInAppPurchaseStatus === "expired"
          ? true
          : el?.subscriptionData?.[0]?.platform === "admin" ||
            el?.subscriptionData?.length === 0
          ? true
          : false,
    };
  });

  return newdata;
};
