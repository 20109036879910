import Loader from "app/loaders/Loader";
import React, { memo, useEffect, useState } from "react";

export default memo(function Counter({ number }) {
  const [count, setCounter] = useState(0);

  useEffect(() => {
    if (count < number) {
      const timer = setTimeout(() => {
        setCounter((prevCount) => prevCount + 1);
      }, 30);

      return () => clearTimeout(timer);
    }
  }, [count, number]);

  return <span>{number!==undefined?count:<Loader size={"small"}/>}</span>;
});
