import { Carousel, ConfigProvider, Modal } from "antd";
import Loader from "app/loaders/Loader";
import React, { useRef, useState } from "react";
import styled from "styled-components";


const ReportByDetailsModal = ({ loading, openModal, handleCancel, detail }) => {
  const carouselRef = useRef(null);
  const [indexCard,setIndexCard] = useState(1)
  const nextcard = () => {
    carouselRef.current?.next();
  };
  const previouscard = () => {
    carouselRef.current?.prev();
  };
  const  beforeChange=(value,index)=>{
    setIndexCard(index+1)
  }

  return (
    <Modal
      width={"fit-content"}
      open={openModal}
      onCancel={handleCancel}
      footer={false}
    >
      <ConfirmModalStyle>
        {loading ? (
          <div className="warppermodal">
            <Loader />
          </div>
        ) : (
          <>
            <div className="reported-to-box">
              <p className="reported-to">Reported To : </p>
              <p className="reported-to-name">Vikas Tomar </p>
            </div>

            <div className="total-reported-box">
              <p className="reported-to">Total Reported</p>
              <p className="reported-to">10</p>
            </div>
            <hr className="hr-line" />
            <ConfigProvider
              theme={{
                components: {
                  Carousel: {
                    arrowSize: 20,
                    dotActiveWidth: 30,
                  },
                },
                token: {
                  colorBgContainer: "#000000",
                },
              }}
            >
              <Carousel
                ref={carouselRef}
                autoplay={false}
                autoplaySpeed={10000}
                arrows={false}
                infinite={false}
                dots={false}
                beforeChange={beforeChange}
              >
                {Array(10)
                  .fill("f")
                  .map((el, index) => {
                    return (
                      <>
                        <div className="reported-details-container">
                          <p className="current-index">{indexCard}</p>
                          <div className="profile-box">
                            <img
                              src="https://d1ondtw2lfyb7f.cloudfront.net/image_cropper_3AD78812-588F-47E1-9948-F2F13F7B148B-14422-0000018526656AEB3D0EB1DC-50C1-4C53-A6E3-7430D1E4E305_compressed.jpg99893428391"
                              className="reported-image-porfile"
                              alt=""
                            />
                            <hr className="hr-line" />
                            <div className="details-box">
                              <div className="details-reported">
                                <p className="reported-lebel">Reported By: </p>
                                <p className="reported-value">Ravinder </p>
                              </div>
                              <hr className="hr-line" />
                              <div className="details-reported">
                                <p className="reported-lebel">Discription:</p>
                                <p className="reported-value">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Carousel>
            </ConfigProvider>

            <div className="btn-contianer-modal">
              {indexCard!==1 && <button
                className="btn-modal"
                onClick={previouscard}
                style={{ color: "green" }}
              >
                Previous
              </button>}
             {indexCard !== 10&& <button
                onClick={nextcard}
                className="btn-modal"
                style={{ color: "green" }}
              >
                Next
              </button>}
            </div>
          </>
        )}
      </ConfirmModalStyle>
    </Modal>
  );
};

export default ReportByDetailsModal;

const ConfirmModalStyle = styled.div`
  width: 400px;
  height: fit-content;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  .reported-to-box {
    display: flex;
    justify-content: start;
    margin: 10px 0;
    font-weight: 700;
    gap: 10px;
  }
  .total-reported-box {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-weight: 700;
    gap: 10px;
  }
  .reported-image-porfile {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }
  .details-box {
    width: calc(100% - 120px);
  }
  .current-index {
    display: flex;
    position: absolute;
    justify-content: end;
    width: 100%;
    color: #008eff;
    font-weight: 600;
    font-size: 16px;
    right: 12px;
    top: 18px;
  }
  .profile-box {
    display: flex;
    justify-content: start;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: inset 0 0 25px #e3e3e3, 3px 3px 5px 0 #ffffff;
  }
  .reported-lebel {
    color: #000000;
    font-weight: 500;
  }
  .reported-value {
    color: #717171;
    font-size: 13px;
    font-weight: 500;
  }
  .btn-contianer-modal {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  .reported-to-name {
    color: red;
    text-decoration: underline;
    letter-spacing: 1px;
  }
  .reported-details-container {
    padding: 10px 0;
    position: relative;
  }
  .btn-modal {
    padding: 5px 12px;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    color: red;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      box-shadow: 0px 0px 0px 1px green;
    }
  }
  .hr-line {
    background: gray;
  }
`;
