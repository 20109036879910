import React, { useEffect, useState } from "react";
import { Progress } from "antd";

const conicColors = {
  "0%": "#db2020",
  "50%": "#0fa5e0",
  "100%": "#1bf137",
};

const CircleProgress = ({ percentage, size }) => {
  const [perc, setPerc] = useState(0);
  const [completed, setcompleted] = useState(0);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (perc <= 100 && completed <= 100) {
        setPerc(perc + 1);
        setcompleted(completed + 1);
      } else if (perc > percentage) {
        setPerc(perc - 1);
      }
    }, 10);
    return () => clearTimeout(timer);
  }, [perc,completed,percentage]);

  return (
    <Progress
      size={size}
      type="circle"
      percent={perc}
      strokeColor={conicColors}
    />
  );
};

export default CircleProgress;
