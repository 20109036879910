import React, { memo, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "app/component/headerNavbars/Header";
import SideNavbar from "app/component/headerNavbars/SideNavbar";

const PrivateLayout = () => {
  const [navopenClose, setNavopenClose] = useState(true);
  const [navopenClose2, setNavopenClose2] = useState(true);
  const [firstime, setFirsttime] = useState(1);

  const handling = () => {
    setFirsttime(firstime + 1);
    setNavopenClose2(false);
    setNavopenClose(!navopenClose);
  };

  return (
    <PrivateLayoutStyle navopenClose={navopenClose} firstime={firstime}>
      <SideNavbar
        navopenClose={navopenClose}
        setNavopenClose={setNavopenClose}
        navopenClose2={navopenClose2}
        firstime={firstime}
        setNavopenClose2={setNavopenClose2}
      />
      <div className="layout-container">
        <div className="layout-outlet">
          <Header navopenClose={navopenClose} handleClick={handling} />
          <div className="layout-components">
            <Outlet />
          </div>
        </div>
      </div>
    </PrivateLayoutStyle>
  );
};

export default memo(PrivateLayout);

const PrivateLayoutStyle = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  background: #c6ebfc;
  position: relative;

  .layout-container {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 5px 10px 10px 10px;

    @media (max-width: 1080px) {
      margin-top: 45px;
    }
  }
  .layout-components {
    width: 100%;
    margin-top: 10px;
  }
  .layout-outlet {
    width: ${({ navopenClose }) =>
      navopenClose ? "calc(100% - 250px)" : "100%"};
    margin-top: ${({ navopenClose }) => (navopenClose ? 0 : 0)};
    animation-name: ${({ navopenClose, firstime }) =>
      navopenClose
        ? firstime === 1
          ? "animlayoutfrist"
          : "animlayout"
        : "animlayout2"};
    animation-duration: 0.4s;

    @keyframes animlayout {
      from {
        width: 100%;
      }
      to {
        width: calc(100% - 250px);
      }
    }
    @keyframes animlayoutfrist {
      from {
        width: calc(100% - 250px);
      }
      to {
        width: calc(100% - 250px);
      }
    }
    @keyframes animlayout2 {
      from {
        width: calc(100% - 250px);
      }
      to {
        width: 100%;
      }
    }
  }
  @media (max-width: 1080px) {
    .layout-container {
      width: 100%;
      padding: 0;
    }
    .layout-components {
      padding: 10px;
    }
    .layout-outlet {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      animation-duration: 0.4s;
      animation-name: animlayoutmo;

      @keyframes animlayoutmo {
        from {
          width: 100%;
        }
        to {
          width: 100%;
        }
      }
    }
  }
`;
