import React, { useState } from "react";
import { Carousel, ConfigProvider } from "antd";
import styled from "styled-components";

let imagebaseUrl = process.env.REACT_APP_IMAGE_URL;

const ImageSlider = ({ imageArray }) => {
  const [imgIndex,setImgIndex] = useState(0)

  const handleSwipe =(value,type)=>{
    setImgIndex(type)
  }


  return (
    <SilderImageStyle>
      <img
        className="image-class-blur"
        src={
          imageArray?.[imgIndex]?.imageUrl && imagebaseUrl + imageArray?.[imgIndex]?.imageUrl
        }
        alt="About Us"
      />
      <ConfigProvider
        theme={{
          components: {
            Carousel: {
              arrowSize:20,
              dotActiveWidth: 30,
            },
          },
          token:{
            colorBgContainer:"#000000"
          }
        }}
      >
        <Carousel autoplay={true} autoplaySpeed={10000}  beforeChange={handleSwipe} arrows  infinite={false}>
          {imageArray?.map((el) => {
            return (
              <div className="image-container">
                <img
                  className="image-class-silder"
                  src={el?.imageUrl && imagebaseUrl + el?.imageUrl}
                  alt="About Us"
                />
              </div>
            );
          })}
        </Carousel>
      </ConfigProvider>
    </SilderImageStyle>
  );
};
export default ImageSlider;


const SilderImageStyle = styled.div`
  height: 100%;
  position: relative;
  .slick-arrow.slick-prev {
  font-size: 10px;
  
}
.ant-carousel .slick-prev::before {
    content: '<';
    display: block;
    position: relative;
    right: 3px;
    bottom: 10px;
    /* width: 100px; */
    font-size: 20px;
    color: gray;
    background-color: white;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
   
}
.slick-arrow.slick-next {
  font-size: 10px;
}
.ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 11px;
  bottom: 10px;
  /* width: 100px; */
  font-size: 20px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
  .image-container {
    max-width: 700px;
    height: 400px;
  }
  .image-class-silder {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .image-class-blur {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    border: 5px solid #000000;
  }
`;
