import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchField from "app/component/SearchField";
import { subscriptionApi } from "services/collection";
import TableComponent from "app/component/tables/TableComponent";
import Selecter from "app/component/Selecter";
import TableAction from "app/component/tables/TableAction";
import SubscriptionsModal from "app/modals/SubscriptionsModal";
import { calculateHeight, errorMessage, snoIndex } from "utils/commonFunction";
import ImageTage from "app/component/ImageTage";
import { subcriptionModify } from "utils/modifyRespones";
import {
  HeaderFilterWrapper,
  HeaderWrapper,
  Heading,
  TotalCounter,
} from "utils/commonStyle";
import {
  bydefaultCurrentPage,
  bydefaultLimit,
  subscriptionOption,
  subscriptionStatusOptions,
} from "utils/constant";
import Cardwrapper from "app/component/tables/Cardwrapper";
import SubTableCard from "./SubTableCard";

const Subscription = () => {
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [search, setSearch] = useState(null);
  const [listing, setListing] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [sortFilter, setSortFilter] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: bydefaultCurrentPage,
    pageSize: bydefaultLimit,
    total: 0,
  });

  const handleSelectors = (value) => {
    setSortFilter(value);
    setPagination({ ...pagination, current: 1 });
  };

  const fetchSubscriptionData = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("limit", pagination?.pageSize);
    params.append("page", pagination?.current);
    if (subscriptionType) params.append("subscriptionType", subscriptionType);
    if (search) params.append("search", search);
    if (sortFilter) params.append("subscriptionStatus", sortFilter);
    const res = await subscriptionApi(params.toString());
    if (res?.status === 200) {
      const result = await subcriptionModify(res?.data?.data);
      setListing(result);
      setPagination((prev) => ({ ...prev, total: res?.data?.totalUsers }));
    } else {
      errorMessage(res);
    }
    setLoading(false);
  };

  const onChange = (pagination) => {
    setPagination(pagination);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleSelected = (value) => {
    setSubscriptionType(value);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleAction = (value) => {
    const sub = value?.subscriptionData?.[0]?.currentInAppPurchaseStatus;
    setId(value?._id);
    if (sub === "cancel" || sub === "expired") {
      setSubscription(null);
    } else {
      setSubscription(value?.subscriptionData?.[0]?.productId || null);
    }
    if (value?.actiontype === "subscriptions") {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, [pagination?.current, subscriptionType, search, sortFilter]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      render: (index, _record) => snoIndex({ index, pagination }),
      width: "60px",
    },
    {
      title: "Name",
      dataIndex: "profileName",
      key: "profileName",
      render: (profileName) => (
        <div className="Image-section">
          <ImageTage
            className="table-image dddd"
            src={profileName?.thumbnail}
          />
          <p>{profileName?.fullName}</p>
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "Subscription type",
      dataIndex: "subscriptionBadgetype",
      key: "subscriptionBadgetype",
    },
    {
      title: "Purchase Date",
      dataIndex: "startDateset",
      key: "startDateset",
    },
    {
      title: "Expiry Date",
      dataIndex: "endDateset",
      key: "endDateset",
    },
    {
      title: "Renewed Date",
      dataIndex: "renewedDateset",
      key: "renewedDateset",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <TableAction
            tableAction={{ subscriptions: true, actionDisabled: true }}
            record={record}
            handlClick={handleAction}
          />
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <>
      <SubscriptionsModal
        openModal={openModal}
        handleCancel={handleCancel}
        id={id}
        fetchSubscriptionData={fetchSubscriptionData}
        subscription={subscription}
      />
      <StyledSubscriptionFilter>
        <HeaderWrapper>
          <Heading>Subscriptions</Heading>
          <HeaderFilterWrapper>
            <SearchField handleSearch={handleSearch} />
            <Selecter
              allowClear={true}
              placeholder={"Subscription"}
              options={subscriptionOption}
              handleSelect={handleSelected}
            />
            <Selecter
              allowClear={true}
              placeholder={"Status"}
              options={subscriptionStatusOptions}
              handleSelect={handleSelectors}
            />
            <TotalCounter>Total Users: {pagination?.total || "0"}</TotalCounter>
          </HeaderFilterWrapper>
        </HeaderWrapper>
        <Cardwrapper
          table={
            <TableComponent
              loading={loading}
              listing={listing}
              columns={columns}
              pagination={pagination}
              onChange={onChange}
              padding={"3px 5px"}
              scrollHeight={calculateHeight(200)}
            />
          }
          tableCard={listing?.map((el) => (
            <SubTableCard handleAction={handleAction} data={el} />
          ))}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
        />
      </StyledSubscriptionFilter>
    </>
  );
};

export default Subscription;

const StyledSubscriptionFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
  }

  .user-select {
    width: 200px;
    max-width: 100%;
  }

  .dashboard-header {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
