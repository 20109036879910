import CoinsManagment from "app/page/coins/CoinsManagment";
import Reported from "app/page/report/Reported";
import Subscription from "app/page/subscription/Subscription";
import UserListing from "app/page/users/UserListing";
import ForgotPassword from "app/auth/ForgotPassword";
import Login from "app/auth/Login";
import Dashboard from "app/page/dashboards/Dashboard";

export const publicPath = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
];

export const privatePath = [
  {
    name: "Deshoard",
    path: "/layout/dashboard",
    element: <Dashboard />,
  },
  {
    name: "User",
    path: "/layout/users",
    element: <UserListing />,
  },
  {
    name: "Reported",
    path: "/layout/reported",
    element: <Reported />,
  },
  {
    name: "Subscription",
    path: "/layout/subscription",
    element: <Subscription />,
  },
  {
    name: "Coins Managment",
    path: "/layout/coins-managment",
    element: <CoinsManagment />,
  },
];
