import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import RoutesSplash from "routes/RoutesSplash";
import store from "./reduxApp/Store";



function App() {
  return (
    <Provider store={store}>
      <RoutesSplash />
    </Provider>
  );
}

export default App;
