import { memo } from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import PageNotfound404 from "utils/PageNotfound404";
import { privatePath, publicPath } from "./RoutesPath";
import PublicLayout from "layouts/PublicLayout";
import PrivateLayout from "layouts/PrivateLayout";

const PublicRoute = ({ isAuth }) => {
  if (isAuth) {
    return <Navigate to="/layout/dashboard" />;
  }
  return <Outlet />;
};

const PrivateRoute = ({ isAuth }) => {
  if (!isAuth) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

const RoutesSplash = () => {
  const token = useSelector((store) => store.authSlice.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotfound404 />} />
        <Route element={<PublicRoute isAuth={token} />}>
          <Route path="/" element={<PublicLayout />}>
            {publicPath?.map((el) => {
              return <Route path={el?.path} element={el?.element} />;
            })}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuth={token} />}>
          <Route path="/layout/" element={<PrivateLayout />}>
            {privatePath?.map((el) => {
              return <Route path={el?.path} element={el?.element} />;
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default memo(RoutesSplash);
