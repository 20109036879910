import { message, Modal } from "antd";
import styled from "styled-components";

import { useEffect, useRef, useState } from "react";
import Loader from "app/loaders/Loader";
import { postSubscriptionApi } from "services/collection";
import SubscriptionType from "app/component/SubscriptionType";

const SubscriptionsModal = ({
  openModal,
  handleCancel,
  id,
  fetchSubscriptionData,
  subscription,
}) => {
  const premiumRef1 = useRef();
  const premiumRef12 = useRef();
  const plusRef1 = useRef();
  const plusRef12 = useRef();
  const [checkValue, setCheckValue] = useState(subscription);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleOk = async () => {
    if (checkValue !== null && checkValue !== "") {
      setErrors(false);
      setLoading(true);
      const params = {
        userId: id,
        subscriptionType: checkValue,
      };
      const res = await postSubscriptionApi(params);
      if (res?.status === 200) {
        setCheckValue(null);
        fetchSubscriptionData();
        message.success(res.message);
      }
      setLoading(false);
      handleCancel();
    } else {
      setErrors(true);
    }
  };

  const onClickCheck = (e) => {
    const { value } = e.target;
    if (premiumRef1.current) premiumRef1.current.checked = false;
    if (premiumRef12.current) premiumRef12.current.checked = false;
    if (plusRef1.current) plusRef1.current.checked = false;
    if (plusRef12.current) plusRef12.current.checked = false;
    if (value === checkValue) {
      setCheckValue("basic");
      e.target.checked = false;
    } else {
      setCheckValue(value);
      e.target.checked = true;
    }
  };

  useEffect(() => {
    if (premiumRef1.current) premiumRef1.current.checked = false;
    if (premiumRef12.current) premiumRef12.current.checked = false;
    if (plusRef1.current) plusRef1.current.checked = false;
    if (plusRef12.current) plusRef12.current.checked = false;
    switch (subscription) {
      case "com.splashmatch.onemonthpremium":
        premiumRef1.current.checked = true;
        break;
      case "com.splashmatch.oneyearpremium":
        premiumRef12.current.checked = true;
        break;
      case "com.splashmatch.onemonthplus":
        plusRef1.current.checked = true;
        break;
      case "com.splashmatch.oneyearplus":
        plusRef12.current.checked = true;
        break;
    }
    subscription && setCheckValue(subscription);
  }, [subscription]);


  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      footer={false}
      width={"fit-content"}
      header={false}
    >
      <ConfirmModalStyle>
        <h2>Subscription Plans</h2>
        <p className="modal-text-parra">
          Unlock Exclusive Benefits with a Subscription!
        </p>
        <div className="radio-btn-container">
          <div className="radio-input-container">
            <p className="label">
              <SubscriptionType type={1} />1 Month Premium
            </p>
            <input
              ref={premiumRef1}
              className="checkId"
              type="checkbox"
              name="premiumRef1"
              value="com.splashmatch.onemonthpremium"
              onChange={onClickCheck}
            />
          </div>
          <hr />
          <div className="radio-input-container">
            <p className="label">
              <SubscriptionType type={1} />
              12 Month Premium
            </p>
            <input
              ref={premiumRef12}
              className="checkId"
              type="checkbox"
              name="premiumRef12"
              value="com.splashmatch.oneyearpremium"
              onChange={onClickCheck}
            />
          </div>
          <hr />
          <div className="radio-input-container">
            <p className="label">
              <SubscriptionType type={2} />1 Month Plus
            </p>
            <input
              ref={plusRef1}
              className="checkId"
              type="checkbox"
              name="plusRef1"
              value="com.splashmatch.onemonthplus"
              onChange={onClickCheck}
            />
          </div>
          <hr />
          <div className="radio-input-container">
            <p className="label">
              <SubscriptionType type={2} />
              12 Month Plus
            </p>
            <input
              ref={plusRef12}
              className="checkId"
              type="checkbox"
              name="plusRef12"
              value="com.splashmatch.oneyearplus"
              onChange={onClickCheck}
            />
          </div>
          <hr />
        </div>
        <p className="errors">{errors && "Please select subscription"}</p>
        <div className="btn-container-modal">
          {loading ? (
            <Loader />
          ) : (
            <button
              onClick={handleOk}
              className="btn-modal"
              style={{ color: "green" }}
            >
              Submit
            </button>
          )}
        </div>
      </ConfirmModalStyle>
    </Modal>
  );
};

export default SubscriptionsModal;

const ConfirmModalStyle = styled.div`
  width: 360px;
  background: radial-gradient(#8fdcff, transparent);
  padding: 20px;
  border-radius: 10px;
  .btn-modal {
    padding: 5px 12px;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    color: red;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      box-shadow: 1px 1px 1px 1px;
    }
  }
  .modal-text-parra {
    color: #510303;
    font-weight: 500;
    font-size: 14px;
  }
  .btn-container-modal {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .radio-btn-container {
    display: flex;
    justify-content: start;
    gap: 12px;
    flex-direction: column;
    margin: 20px 0;
  }
  .radio-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #535353;
  }
  .errors {
    height: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: red;
  }
  .deleted {
    font-size: 18px;
    color: #f7454a;
    font-weight: 600;
  }
  .deleted:hover {
    color: red;
  }
`;
