import React, { useState } from "react";
import styled from "styled-components";
import CircleProgress from "app/component/CircleProgress";
import UserImageModal from "app/modals/UserImageModal";
import BlockUser from "app/modals/BlockUser";
import ReportByDetailsModal from "app/modals/ReportByDetailsModal";
import ImageTage from "app/component/ImageTage";
import { datePattern } from "utils/commonFunction";

const ReportedCard = ({ blocked, details }) => {
  const detail = details;
  const [imageModalopen, setImageModalOpen] = useState(false);
  const [blockopen, setBlockopen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(detail?.recieverdata?.idActive);

  const handleCancelImage = () => setImageModalOpen(false);
  const handleCancelblock = () => setBlockopen(false);
  const handleCancelReport = () => setReportOpen(false);

  const blockeding = async () => {
    setLoading(true);
    let res = await blocked();
    if (res) {
      setStatus(!status);
      handleCancelblock();
      setLoading(false);
    }
  };

  return (
    <>
      <BlockUser
        openModal={blockopen}
        handleCancel={handleCancelblock}
        loading={loading}
        handleOk={blockeding}
        title={"User Status"}
        msg={status}
      />
      <ReportByDetailsModal
        openModal={reportOpen}
        handleCancel={handleCancelReport}
        loading={loading}
        detail={detail}
      />

      <UserImageModal
        imageArray={details?.recieverdata?.images}
        openModal={imageModalopen}
        handleCancel={handleCancelImage}
      />

      <UserDetailStyle>
        <div className="Userdetail">
          <div className="user-image-wrapper">
            <div className="profile-conatainer">
              <div className="profile">
                <div className="progess-circles">
                  <CircleProgress
                    percentage={details?.recieverdata?.profilePercentage || 1}
                    size={160}
                  />
                </div>
                <div className="image-user-box">
                  <ImageTage
                    className="user-image"
                    src={details?.recieverdata?.images?.[0]?.imageUrl}
                  />
                </div>
              </div>
              <div className="flex-box-name">
                <p className="profile-per">
                  {details?.recieverdata?.profilePercentage || 0}% Profile
                  Complete
                </p>
                <h3 className="user-name-para">
                  {details?.recieverdata?.firstName}{" "}
                  {details?.recieverdata?.lastName}
                </h3>
              </div>
            </div>
            <div>
              <div
                className="more-image-box"
                onClick={() =>
                  details?.recieverdata?.images?.[0]?.imageUrl &&
                  setImageModalOpen(true)
                }
              >
                <div className="more-images">
                  <ImageTage
                    className="user-more-img"
                    src={details?.recieverdata?.images?.[0]?.imageUrl}
                  />
                  <p className="image-count">
                    +{details?.recieverdata?.images.length-1}
                  </p>
                </div>
                <p>Preview</p>
              </div>
            </div>
          </div>
          <hr className="hr-linei" />
          <div className="flex-box">
            <div className="name-box">
              <div className="status-container">
                <p className="detail-label"> Profile Status : </p>
                {detail?.recieverdata?.idActive ? (
                  <p style={{ color: "green", fontWeight: 600 }}> Active</p>
                ) : (
                  <p style={{ color: "red", fontWeight: 600 }}> Inactive</p>
                )}
              </div>
            </div>

            <div
              className="status-btn"
              onClick={() => setBlockopen(true)}
              style={{
                color: detail?.recieverdata?.idActive ? "red" : "green",
              }}
            >
              {detail?.recieverdata?.idActive ? "Deactivate" : "Activate"}
            </div>
          </div>
          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <p className="detail-label">Reported By :</p>
              <p className="detail-value">{details?.repotedBy || "N/A"}</p>
            </div>

            {/* <div
              className="status-btn"
              onClick={() => setReportOpen(true)}
              style={{ color: "green" }}
            >
            Details
            </div> */}
          </div>
          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <p className="detail-label">Reported Date :</p>
              <p className="detail-value">{datePattern(details?.createdAt)}</p>
            </div>
          </div>
          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon">
              <p className="detail-label">Reported Reason</p>
            </div>
          </div>

          <div className="detail-row">
            <div className="icon">
              <p className="detail-label" style={{ fontWeight: 500 }}>
                Title :{" "}
              </p>
              <p className="detail-value">{details?.reportTitle}</p>
            </div>
          </div>
          <hr className="hr-linei" />
          <div className="detail-row">
            <div className="icon-describe">
              <p className="detail-label describe">Description</p>
              <p className="detail-value">{details?.reportDescription}</p>
            </div>
          </div>
        </div>
      </UserDetailStyle>
    </>
  );
};

export default ReportedCard;

const UserDetailStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  font-family: Arial, sans-serif;

  .hr-linei {
    background: gray;
  }
  .user-info {
    margin: 10px 0;
  }
  .flex-box-name {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .describe {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: start;
    gap: 5px;
  }

  .profile {
    position: relative;
    .ant-progress-text {
      display: none;
    }
  }
  .more-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-weight: 600;
    cursor: pointer;
  }
  .heading-para {
    background-color: #007bff;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #0056b3;
  }
  .more-images {
    position: relative;
    width: 70px;
    height: 70px;
  }

  .user-more-img {
    width: 100%;
    height: 100%;
    filter: blur(2px);
    border-radius: 15px;
  }
  .image-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
  .icon-describe {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }

  .Userdetail {
    width: 420px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0 auto; 
    @media (max-width: 420px) {
      width: 100%;
      
    }
  }

  .user-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .progess-circles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .image-user-box {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  .name-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .active-btn {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 20px;
    color: red;
    margin-left: 10px;
    font-weight: 600;
    cursor: pointer;
  }
  .status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 10px 0;
  }
  .status-btn {
    width: 100px;
    height: 35px;
    padding: 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 20px;
    color: red;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #dbdbdb;
    }
  }
  .eyes-images-show {
    color: #000000;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    opacity: 0.8;
    gap: 5px;
    &:hover {
      opacity: 1;
    }
  }

  .user-image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
  .flex-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    align-items: center;
  }
  .profile-per {
    text-align: start;
    font-weight: 600;
    margin: 5px 0;
    padding: 0;
  }
  .user-detail {
    margin-top: 20px;
  }
  .sub-type {
    display: flex;
    gap: 5px;
    font-weight: 600;
  }

  .detail-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 15px;
  }
  .ageicon {
    width: 16px;
    height: 16px;
  }

  .detail-label {
    font-size: 16px;
    font-weight: 600;
    // color: #black;
  }

  .detail-value {
    font-size: 14px;
    font-weight: 500;
    color: #464545;
  }

  .icon {
    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: start;
    align-items: center;
  }

  .counter {
    display: flex;
    justify-content: space-between;
  }

  .name {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 18px;
  }

  .Likes {
    display: flex;
    flex-direction: column;
    padding: 4px 0 0 0;
    align-items: center;
  }

  .para {
    font-weight: 600;
  }

  .user-name-para {
    text-align: center;
    font-weight: 600;

    padding: 0;
  }
  @media (max-width: 488px) {
    width: 100%;
  }
`;
