import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaUserGroup } from "react-icons/fa6";
import { Heading, TableActionSpan } from "utils/commonStyle";
import { dashboardApi } from "services/collection";
import { dashboardModify } from "utils/modifyRespones";
import TableAction from "app/component/tables/TableAction";
import TableComponent from "app/component/tables/TableComponent";
import UserDetailModal from "app/modals/UserDetailModal";
import { calculateHeight, successMessage } from "utils/commonFunction";
import Counter from "app/component/Counter";
import Cardwrapper from "app/component/tables/Cardwrapper";
import DashoardTableCard from "./DashoardTableCard";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(false);
  const [listing, setListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const DetaiDashboard = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("limit", 50);
    params.append("page", 1);
    const res = await dashboardApi(params);
    if (res?.status === 200) {
      const result = await dashboardModify(res?.data?.[0]?.latestUsers);
      setListing(result);
      setDetails(res?.data?.[0]?.stats);
    } else {
      successMessage(res);
    }
    setLoading(false);
  };

  const handleAction = (value) => {
    setId(value?._id);
    if (value?.actiontype === "info") {
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    DetaiDashboard();
  }, []);

  const columns = [
    {
      title: "S No.",
      dataIndex: "index",
      key: "index",
      width: "60px",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (profileName, record) => {
        return (
          <TableActionSpan
            onClick={() => handleAction({ ...record, actiontype: "info" })}
          >
            {profileName}
          </TableActionSpan>
        );
      },
    },
    {
      title: "Joined",
      dataIndex: "joinedDate",
      key: "joinedDate",
    },
    {
      title: "Gender",
      dataIndex: "identity",
      key: "identity",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "210px",
    },
    {
      title: "Login Type",
      dataIndex: "loginTypecheck",
      key: "loginTypecheck",
    },

    {
      title: "Status",
      dataIndex: "checkStatus",
      key: "checkStatus",
      width: "100px",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <TableAction
            tableAction={{  info: true }}
            record={record}
            handlClick={handleAction}
          />
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <StyledDashboard>
      <UserDetailModal
        openModal={open}
        handleCancel={handleCancel}
        userId={id}
      />
      <div className="user-active-container">
        <div className="dashboard-header">
          <div className="card" onClick={() => navigate("/layout/users")}>
            <div className="icon">
              <FaUserGroup />
            </div>
            <div className="text-container">
              <div className="card-title">Total</div>
              <div className="card-value">
                <Counter number={details?.totalUsers} />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <FaUserGroup />
            </div>
            <div className="text-container">
              <div className="card-title">Active</div>
              <div className="card-value" style={{ color: "green" }}>
                <Counter number={details?.totalActiveUsers} />
              </div>
            </div>
          </div>
          <div className="card" onClick={() => navigate("/layout/reported")}>
            <div className="icon">
              <FaUserGroup />
            </div>
            <div className="text-container">
              <div className="card-title">Reported</div>
              <div className="card-value" style={{ color: "red" }}>
                <Counter number={details?.totalReportedUsers} />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <FaUserGroup />
            </div>
            <div className="text-container">
              <div className="card-title">Online</div>
              <div className="card-value color-changes">
                <Counter number={details?.totalOnlineUsers} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Heading>Recent Users</Heading>

      <Cardwrapper
        table={
          <TableComponent
            loading={loading}
            listing={listing}
            columns={columns}
            pagination={false}
            scrollHeight={calculateHeight(245)}
          />
        }
        tableCard={listing?.map((el) => (
          <DashoardTableCard
            key={el.id}
            handleAction={handleAction}
            data={el}
          />
        ))}
        loading={loading}
        pagination={false}
      />
    </StyledDashboard>
  );
};

export default Dashboard;

const StyledDashboard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .table-actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .user-select {
    width: 200px;
  }

  .total-users {
    font-weight: bold;
    color: #333;
  }

  .color-changes {
    animation: coloring 1s linear infinite;
    color: #181818;

    @keyframes coloring {
      to {
        color: #181818;
        color: #345e34;
        color: #802900;
        color: #76d476;
        color: #800000;
        color: green;
        color: green;
        color: #ff0095;
        color: green;
        color: green;
      }
    }
  }

  .dashboard-header {
    padding-top: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: auto;
    }
  }

  .user-active-container {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100px;
  }

  .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
  }

  .card-title {
    padding-top: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .card-value {
    font-size: 26px;
    font-weight: bold;
    color: #007bff;
    margin: 0;
    padding: 0;
  }

  .icon {
    width: 50%;
    height: 100%;
    background: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }

  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }

  .table-actions {
    flex-direction: column;
    gap: 8px;
  }

  .user-select {
    width: 100%;
  }
`;
