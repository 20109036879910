import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { bockUserApi, reportedApi } from "services/collection";
import SearchField from "app/component/SearchField";
import TableComponent from "app/component/tables/TableComponent";
import TableAction from "app/component/tables/TableAction";
import Selecter from "app/component/Selecter";
import BlockUser from "app/modals/BlockUser";
import ReportUserDetailModal from "./ReportUserdetail";
import ImageTage from "app/component/ImageTage";
import { reportModify } from "utils/modifyRespones";
import {
  calculateHeight,
  errorMessage,
  snoIndex,
  successMessage,
} from "utils/commonFunction";
import {
  bydefaultCurrentPage,
  bydefaultLimit,
  sortOptions,
  statusOption,
} from "utils/constant";
import {
  HeaderFilterWrapper,
  HeaderWrapper,
  Heading,
  TotalCounter,
} from "utils/commonStyle";
import ReportTableCard from "./ReportTableCard";
import Cardwrapper from "app/component/tables/Cardwrapper";

const Reported = () => {
  const [open, setOpen] = useState(false);
  const [blockopen, setBlock] = useState(false);
  const [search, setSearch] = useState(null);
  const [id, setId] = useState("");
  const [idActive, setIdActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listing, SetListing] = useState([]);
  const [reportDetails, setreportDetails] = useState();
  const [sortFilter, setSortFilter] = useState(false);
  const [status, setStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: bydefaultCurrentPage,
    pageSize: bydefaultLimit,
    total: 0,
  });

  const getListing = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("limit", pagination?.pageSize);
    params.append("page", pagination?.current);
    status && params.append("shortActiveUsers", status);
    sortFilter && params.append("sortFilter", sortFilter);
    search && params.append("search", search);
    const res = await reportedApi(params.toString());

    if (res?.status === 200) {
      const result = await reportModify(res?.data?.data);
      SetListing(result);
      setPagination({ ...pagination, total: res?.data?.totalUsers });
    } else {
      errorMessage(res);
    }
    setLoading(false);
  };

  function onChange(pagination) {
    setPagination(pagination);
  }

  const handleSelectStatus = (value) => {
    setStatus(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleAction = (value) => {
    setId(value?.recieverId);
    setIdActive(value?.recieverdata?.idActive);
    if (value?.actiontype === "info") {
      setOpen(true);
      setreportDetails(value);
    } else if (value?.actiontype === "block") {
      setBlock(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setBlock(false);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleSelected = (value) => {
    setSortFilter(value);
    setPagination({ ...pagination, current: 1 });
  };

  const blocked = async () => {
    setConfirmLoading(true);
    const params = {
      recieverId: id,
      status: "2",
    };
    const res = await bockUserApi(params);
    if (res?.status === 200) {
      successMessage(res);
      getListing();
    } else {
      //this comment for direct rendering page no 1
      //   if (pagination?.current === 1) getListing();
      //   else setPagination({ ...pagination, current: 1 });
      // } else {
      errorMessage(res);
    }
    setConfirmLoading(false);
    handleCancel();
    return res?.message;
  };

  useEffect(() => {
    getListing();
  }, [pagination?.current, search, sortFilter, status]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      render: (index) => snoIndex({ index, pagination }),
      width: "60px",
    },
    {
      title: "Name",
      dataIndex: "profileName",
      key: "profileName",
      render: (profileName, record) => (
        <div
          className="Image-section"
          onClick={() => handleAction({ ...record, actiontype: "info" })}
        >
          <ImageTage className="table-image" src={profileName?.thumbnail} />
          <p>{profileName?.fullName}</p>
        </div>
      ),
    },
    {
      title: "Reported By",
      dataIndex: "repotedBy",
      key: "repotedBy",
    },
    {
      title: "Reported Reason",
      dataIndex: "repotedreason",
      key: "repotedreason",
    },

    {
      title: "Report Date",
      dataIndex: "reportedDate",
      key: "reportedDate",
    },

    {
      title: "Status",
      dataIndex: "checkStatus",
      key: "checkStatus",
      width: "100px",
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <TableAction
            record={record}
            handlClick={handleAction}
            tableAction={{
              info: true,
              block: true,
            }}
          />
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <>
      <ReportUserDetailModal
        openModal={open}
        handleCancel={handleCancel}
        detail={reportDetails}
        blocked={blocked}
      />
      <BlockUser
        openModal={blockopen}
        handleCancel={handleCancel}
        loading={confirmLoading}
        handleOk={blocked}
        title={"User Status"}
        msg={idActive}
      />
      <StyledDashboard>
        <HeaderWrapper>
          <Heading> Reported Users</Heading>
          <HeaderFilterWrapper>
            <SearchField handleSearch={handleSearch} />
            <Selecter
              allowClear={true}
              placeholder={"Sort By"}
              options={sortOptions}
              handleSelect={handleSelected}
            />
            <Selecter
              allowClear={true}
              placeholder={"Status"}
              options={statusOption}
              handleSelect={handleSelectStatus}
            />
            <TotalCounter>Total Users: {pagination?.total || "0"}</TotalCounter>
          </HeaderFilterWrapper>
        </HeaderWrapper>

        <Cardwrapper
          table={
            <TableComponent
              loading={loading}
              listing={listing}
              columns={columns}
              pagination={pagination}
              onChange={onChange}
              padding={"3px 5px"}
              scrollHeight={calculateHeight(200)}
            />
          }
          tableCard={listing?.map((el) => (
            <ReportTableCard
              key={el.id}
              handleAction={handleAction}
              data={el}
            />
          ))}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
        />
      </StyledDashboard>
    </>
  );
};

export default Reported;

const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .user-select {
    width: 200px;
  }

  .dashboard-header {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
  }

  .serial-no {
    text-align: center;
  }

  .Image-section {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 14px;
    cursor: pointer;
  }
`;
