import styled from "styled-components";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

const PageNotfound404 = () => {
  const navigate = useNavigate();

  return (
    <PageNotfound404Style>
      <h1 className="text-404-h1">404</h1>
      <p className="error-para-first">OPPS! PAGE NOT FOUND</p>
      <div>
        <p className="error-para-second">
          Sorry, the page your're looking for doesn't exist.
        </p>
      </div>
      <button className="error-btn" onClick={() => navigate(-1)}>
        RETURN
      </button>
    </PageNotfound404Style>
  );
};

export default memo(PageNotfound404);

const PageNotfound404Style = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  gap: 20px;
  background: #121212;

  .text-404-h1 {
    margin: -20px;
    text-align: center;
    font-size: 82px;
    background: -webkit-linear-gradient(#fe22f6, #0edbfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .error-para-first {
    text-align: center;
    font-size: 32px;
    background: -webkit-linear-gradient(#fe22f6, #9c4f4f, #0edbfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .error-para-second {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.9;
    font-family: "Poppins";
  }
  .btn-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-btn {
    padding: 5px 14px;
    border-radius: 4px;
    border: navajowhite;
    font-size: 16px;
    font-weight: 500;
    background: gray;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: white;
    }
  }
`;
