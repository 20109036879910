import { Modal } from "antd";
import styled from "styled-components";

const LogoutModal = ({ openModal, handleCancel, handleOk }) => {
  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      footer={false}
      width={"fit-content"}
      closable={false}
    >
      <ConfrimModalStyle>
        <h2>Logout</h2>
        <p className="modal-text-parra" style={{ color: "red" }}>
          Are you sure to want to logout ?
        </p>
        <div className="btn-contianer-modal">
          <>
            <button
              className="btn-modal"
              style={{ color: "red" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              onClick={handleOk}
              className="btn-modal"
              style={{ color: "green" }}
            >
              Logout
            </button>
          </>
        </div>
      </ConfrimModalStyle>
    </Modal>
  );
};

export default LogoutModal;

const ConfrimModalStyle = styled.div`
  background: radial-gradient(#8fdcff, transparent);
  padding: 20px;
  border-radius: 10px;
  .btn-modal {
    padding: 5px 12px;
    border: none;
    border-radius: 8px;
    background: #ffffff;
    color: red;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      box-shadow: 1px 1px 1px 1px;
    }
  }
  .modal-text-parra {
    color: black;
    font-weight: 500;
    font-size: 16px;
  }
  .btn-contianer-modal {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
  }
`;
